import React, { FC, useMemo, useState } from "react";
import "./index.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import "swiper/scss/autoplay";
import img1 from "@/assets/images/portfolio_logos/Azuro.png";
import img2 from "@/assets/images/portfolio_logos/Bitquery.png";
import img3 from "@/assets/images/portfolio_logos/Carry.png";
import img4 from "@/assets/images/portfolio_logos/Chiliz.png";
import img5 from "@/assets/images/portfolio_logos/Clearpool.png";
import img6 from "@/assets/images/portfolio_logos/Coldstack.png";
import img7 from "@/assets/images/portfolio_logos/DeHorizon.png";
import img8 from "@/assets/images/portfolio_logos/Fairside.png";
import img9 from "@/assets/images/portfolio_logos/Harmony.png";
import img10 from "@/assets/images/portfolio_logos/Klaytn.png";
import img11 from "@/assets/images/portfolio_logos/Marlin.png";
import img12 from "@/assets/images/portfolio_logos/mobilecoin.png";
import img13 from "@/assets/images/portfolio_logos/NKN.png";
import img14 from "@/assets/images/portfolio_logos/Perl.png";
import img15 from "@/assets/images/portfolio_logos/Plotx.png";
import img16 from "@/assets/images/portfolio_logos/Prosper.png";
import img17 from "@/assets/images/portfolio_logos/Shyft.png";
import img18 from "@/assets/images/portfolio_logos/Solv.png";
import img19 from "@/assets/images/portfolio_logos/Symbiosis.png";
import img20 from "@/assets/images/portfolio_logos/Syntropy.png";
import img21 from "@/assets/images/portfolio_logos/Tellor.png";
import img22 from "@/assets/images/portfolio_logos/Tfl.png";
import { useResize } from "@/modules/shared";
import { Autoplay } from "swiper";

const setAutoplayConfigsFunc: any = [];

const SwiperSlider: FC = () => {
  const { windowWidth } = useResize();

  const mobileView = useMemo(() => {
    return windowWidth <= 600;
  }, [windowWidth]);

  const tabView = useMemo(() => {
    return windowWidth <= 1000;
  }, [windowWidth]);

  const [autoplayConfigs, setAutoplayConfigs] = useState(false);

  setAutoplayConfigsFunc[0] = setAutoplayConfigs;

  let slidesPerView = tabView ? 2 : 4;
  slidesPerView = mobileView ? 1 : slidesPerView;
  let loopAdditionalSlides = tabView ? 4 : 5;
  loopAdditionalSlides = mobileView ? 3 : loopAdditionalSlides;
  const spaceBetween = mobileView ? -110 : -100;

  return (
    <div>
      {!autoplayConfigs && (
        <div className="swiper-slider__container">
          {!mobileView && <div className="pixelate-left" />}
          <Swiper
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            speed={1000}
            loop
            loopAdditionalSlides={loopAdditionalSlides}
            pagination={{ clickable: false }}
            onAutoplayStart={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.add("wave-animation");
              }
            }}
            onAutoplayResume={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.add("wave-animation");
              }
            }}
            onAutoplayPause={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.remove("wave-animation");
              }
            }}
          >
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img21} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img22} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img1} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img2} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img3} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img4} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img5} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img6} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img7} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img8} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img9} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img10} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img11} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img12} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img13} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img14} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img15} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img16} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img17} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img18} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img19} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img20} />
              </div>
            </SwiperSlide>
          </Swiper>
          {!mobileView && <div className="pixelate-right" />}
        </div>
      )}{" "}
      {autoplayConfigs && (
        <div className="swiper-slider__container">
          {!mobileView && <div className="pixelate-left" />}
          <Swiper
            modules={[Autoplay]}
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
              waitForTransition: true,
            }}
            speed={1000}
            loop
            loopAdditionalSlides={loopAdditionalSlides}
            pagination={{ clickable: false }}
            onAutoplayStart={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.add("wave-animation");
              }
            }}
            onAutoplayResume={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.add("wave-animation");
              }
            }}
            onAutoplayPause={() => {
              const elements = document.getElementsByClassName("swiper-box");
              for (let i = 0; i < elements.length; i += 1) {
                elements[i].classList.remove("wave-animation");
              }
            }}
          >
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img21} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img22} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img1} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img2} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img3} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img4} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img5} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img6} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img7} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img8} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img9} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img10} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img11} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img12} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img13} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img14} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img15} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img16} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img17} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img18} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img19} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-box">
                <img alt="first_slide" src={img20} />
              </div>
            </SwiperSlide>
          </Swiper>
          {!mobileView && <div className="pixelate-right" />}
        </div>
      )}
    </div>
  );
};

export { SwiperSlider, setAutoplayConfigsFunc };
